<template>
  <div>
    <div class="feed-top relative">
      <div class="dd-container">
        <div class="dd-title-container mb-50px">
          <div class="horizontalLine"></div>
          <span class="title">业务咨询</span>
          <div class="horizontalLine"></div>
        </div>

        <div>
          <el-form label-position="right" :model="form" :rules="rules" ref="form">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="form.name" placeholder="输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="contact">
              <el-input v-model="form.contact" placeholder="输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="咨询内容" prop="content">
              <el-input
                type="textarea"
                :rows="6"
                resize="none"
                placeholder="输入咨询内容"
                v-model="form.content"
              ></el-input>
            </el-form-item>
            <el-button class="btn" type="primary" round @click="submitForm('form')">即刻咨询</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from '~/api/home'

import { Message } from 'element-ui'

export default {
  data() {
    const validPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      const reg = /^(\d{3,4}-)?\d{7,8}$/
      if (!phoneReg.test(value) && !reg.test(value)) {
        callback(new Error('请输入合法的手机号或者座机号'))
      } else {
        callback()
      }
    }

    return {
      form: {
        name: '',
        contact: '',
        content: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: ['blur', 'change'] },
          { min: 1, max: 10, message: '请输入姓名长度在 1 到 10 个字符', trigger: ['blur', 'change'] },
        ],
        contact: [
          { required: true, message: '请输入联系方式', trigger: ['blur', 'change'] },
          { validator: validPhone, trigger: 'blur' },
        ],
        content: [{ required: true, message: '请输入咨询内容', trigger: ['blur', 'change'] }],
      },
    }
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            const res = await homeApi.feedback({
              name: this.form.name,
              contact: this.form.contact,
              content: this.form.content,
            })

            if (res.ok && res.code === 200) {
              Message.success({
                message: '信息提交成功',
                offset: 30,
              })

              this.$refs[formName].resetFields()
            } else {
              Message.error({
                message: '接口异常，请稍后重试',
                offset: 30,
              })
            }
          } catch {
            Message.error({
              message: '接口异常，请稍后重试',
              offset: 30,
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.feed-top {
  padding-top: 60px;
  background-color: rgba(225, 228, 237, 0.3);
}

.btn {
  position: relative;
  left: 50%;
  margin-top: 10px;
  margin-bottom: 70px;
  padding: 0;
  width: 195px;
  height: 58px;
  border-radius: 29px;
  background: #0c56ae;
  color: #ffffff;
  font-weight: 500;
  font-size: 22px;
  line-height: 58px;
  transform: translate(-50%);
}

.feed-bottom {
  width: 100%;
  height: 230px;
}

::v-deep {
  .el-form-item__label {
    padding-right: 40px;
    width: 160px;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 60px;

    &::before {
      display: none;
    }
  }

  .el-input {
    width: 400px;
    height: 60px;
    background: #ffffff;
  }

  .el-textarea {
    width: 400px;
    background: #ffffff;
  }

  .el-input__inner {
    height: 60px;
  }

  .el-form-item {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-left: -120px;
  }
}

</style>
