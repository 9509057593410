<template>
  <div>
    <Banner />
    <News />
    <Feedback />
  </div>
</template>

<script>
import Banner from '@pc/home/Banner'
import News from '@pc/home/News'
import Feedback from '@pc/home/Feedback'

export default {
  components: {
    Banner,
    News,
    Feedback,
  },
  async fetch({ store, app }) {
    await store.dispatch('home/getHomeData', app)
  },
}
</script>
