<template>
  <div class="banner-container" v-if="bannerList.length">
    <div v-swiper="swiperOption">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(banner, index) in bannerList" :key="banner.id" :data-my-index="index">
          <div
            class="banner-item"
            :style="{
              background: 'url(\'' + banner.webImg + '\') center no-repeat',
              cursor: banner.type ? 'pointer' : 'auto',
              backgroundSize: '1920px 520px',
            }"
          ></div>
        </div>
      </div>

      <div v-if="bannerList.length > 1" class="custom-wrap animate__animated animate__fadeIn">
        <div class="pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

if (process.client) {
  const VueAwesomeSwiper = require('vue-awesome-swiper')
  Vue.use(VueAwesomeSwiper)
}
let vm = null
export default {
  computed: {
    ...mapState('home', ['bannerList']),
  },
  watch: {
    bannerList: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.swiperOption = {
          threshold: 10,
          speed: 400,
          loop: true,
          direction: 'horizontal',
          paginationClickable: true,
          autoplay:
            this.bannerList && this.bannerList.length > 1
              ? {
                  delay: 5000,
                  disableOnInteraction: false,
                }
              : false,
          pagination: {
            el: '.pagination',
            clickable: true,
          },
          on: {
            click: function () {
              if (this.clickedSlide && this.clickedSlide.dataset) {
                const realIndex = Number(this.clickedSlide.dataset.myIndex)
                vm.handleClickSlide(realIndex)
              }
            },
          },
        }
      },
    },
  },
  data() {
    return {
      swiperOption: {
        threshold: 10,
        speed: 400,
        loop: true,
        direction: 'horizontal',
        paginationClickable: true,
        // autoplay:
        //   this.bannerList && this.bannerList.length > 1
        //     ? {
        //         delay: 5000,
        //         disableOnInteraction: false,
        //       }
        //     : false,
        pagination: {
          el: '.pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
        },
        on: {
          click: function () {
            if (this.clickedSlide && this.clickedSlide.dataset) {
              const realIndex = Number(this.clickedSlide.dataset.myIndex)
              vm.handleClickSlide(realIndex)
            }
          },
        },
      },
    }
  },
  created() {
    vm = this
  },
  methods: {
    handleClickSlide(reindex) {
      const item = this.bannerList.find((item, itemIndex) => itemIndex === reindex)
      if (!(item && item.webImg && item.type)) return

      // 外链
      this.$openWin(item.linkUrl, !item.linkUrl.includes(location.host))
    },
  },
}
</script>

<style lang="scss" scoped>

.banner-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 520px;

  .custom-wrap {
    position: absolute;
    bottom: 40px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .button {
      cursor: pointer;
    }
  }

  ::v-deep .swiper-container {
    height: 100%;

    .swiper-pagination-bullets {
      display: flex;
      padding: 0 6px;
    }

    .swiper-pagination-bullet {
      margin: 0 4px;
      width: 12px;
      height: 12px;
      border: 1px solid #ffffff;
      border-radius: unset;
      background: #ffffff;
      opacity: 0.5;
    }

    .swiper-pagination-bullet-active {
      background: #fff;
      opacity: 1;
    }
  }

  .banner-item {
    height: 100%;
  }
}

</style>
