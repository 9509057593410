<template>
  <div class="dd-container">
    <div class="dd-title-container mt-60px mb-50px">
      <div class="horizontalLine"></div>
      <span class="title">业务介绍</span>
      <div class="horizontalLine"></div>
    </div>

    <div class="item flex justify-between">
      <div class="item-child w-360px h-229px">
        <p class="title">技术实施</p>
        <p class="desc">一流的团队，专业的技术能力，伟润提供从产品、设计、开发、测试到维护的全价值链IT服务</p>
      </div>

      <div class="item-child w-360px h-229px">
        <p class="title">数字营销</p>
        <p class="desc">数字互联，数据互通，伟润助力企业优化业务流程，提升用户体验，直达用户所需</p>
      </div>

      <div class="item-child w-360px h-229px">
        <p class="title">技术咨询</p>
        <p class="desc">线下线上融合，以领先的IT服务能力和深远的行业洞察，助力企业数字化转型、信息服务生态建设</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

.dd-container {
  margin-bottom: 80px;
}

.item {
  color: #fff;
  line-height: 27px;

  .title {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }

  .item-child {
    padding: 53px 30px;

    .desc {
      font-size: 18px;
    }

    &:first-child {
      background: url('~/assets/img/common/img_3.png') -20px no-repeat;
      background-size: 400px 269px;
    }

    &:nth-child(2) {
      background: url('~/assets/img/common/img_4.png') -20px no-repeat;
      background-size: 400px 269px;
    }

    &:last-child {
      background: url('~/assets/img/common/img_5.png') -20px no-repeat;
      background-size: 400px 269px;
    }
  }
}

</style>
